// Composable that fetches and caches menus and menu items,
// builds parent-child relationships, finds the active menu item(s),
// and maps paths for system pages.
export const useCmsMenu = async (slug: string, options?: { clearState?: boolean }) => {
  const allMenus = useState<Menu[]>('menus', () => [])
  const allMenuItems = useState<ContentMenuItem[]>('menuItems', () => [])

  // Clear the state when the option is set. This was necessary for meedoen, where users could switch between
  // several contexts, and the menu items were different for each context.
  if (options?.clearState === true) {
    allMenus.value = []
    allMenuItems.value = []
  }

  // console.log(`[useCmsMenu] initial allMenus`, allMenus.value, slug)
  // console.log(`[useCmsMenu] initial allMenuItems`, allMenuItems.value)

  const route = useRoute()
  const { findSystemPageById } = useSystemPages()

  async function fetch() {
    const { menus } = await fetchMenus()
    const { menuItems } = await fetchMenuItems({ 'page[size]': 1_000_000 })

    if (menus.value && menuItems.value) {
      allMenus.value = menus.value
      allMenuItems.value = menuItems.value
    }
  }

  // By default, only fetch when the state is empty.
  if (!allMenus.value.length || !allMenuItems.value.length) {
    await fetch()
  }

  const prependSlash = (path: string) => {
    return path.startsWith('/') ? path : `/${path}`
  }

  // Get the system path, which are defined client side.
  const getPathForItem = (item: ContentMenuItem) => {
    if (item.linkType == 'content_page') {
      return prependSlash(item.path)
    }
    if (item.systemPageId) {
      const systemPage = findSystemPageById(item.systemPageId)
      if (systemPage) {
        return systemPage.path
      }
    }
    // TODO: report error to sentry
    console.error(`No path found for menu item`, item)
    return '/'
  }

  // Map system paths, which are defined client side.
  const mapPaths = (items: ContentMenuItem[]) => {
    return items.map((item) => {
      return { ...item, ...{ path: getPathForItem(item) } }
    })
  }

  const menu = computed(() => {
    return allMenus.value?.find(menu => menu.slug == slug)
  })

  // console.log(`menu`, menu.value)

  const parentMenuItems = computed(() => {
    return mapPaths(
      allMenuItems.value?.filter(
        (item: ContentMenuItem) =>
          item.menuId == menu.value?.id && !item.parentId,
      ),
    )
  })

  const parentExistsInMenu = (parentId: id) => {
    return !!parentMenuItems.value.find(
      parentItem => parentItem.id == parentId,
    )
  }

  // Assuming there aren't two menu items with the same path.
  // Find active childmenu item first
  const activeSubItem = computed(() => {
    return allMenuItems.value?.find(
      item =>
        prependSlash(item.path) == route.path
        && !!item.parentId
        && parentExistsInMenu(item.parentId),
    )
  })

  // console.log(`activeSubItem`, activeSubItem.value)

  const activeMainItem = computed(() => {
    if (activeSubItem.value) {
      // If the current route matches a childmenu item, find the corresponding main menu item
      return parentMenuItems.value?.find(
        mainItem =>
          activeSubItem.value && mainItem.id == activeSubItem.value.parentId,
      )
    }
    else {
      // If the current route matches a main menu item directly
      return parentMenuItems.value?.find(
        item => prependSlash(item.path) == route.path,
      )
    }
  })

  const getChildMenuItemsForMainItem = (
    mainItem: ContentMenuItem | undefined,
  ) => {
    return mapPaths(
      allMenuItems.value?.filter(
        item => mainItem && !!item.parentId && item.parentId == mainItem.id,
      ),
    )
  }

  const activeChildMenuItems = computed(() => {
    return getChildMenuItemsForMainItem(activeMainItem.value)
  })

  return {
    menu,
    parentMenuItems,
    activeChildMenuItems,
    activeMainItem,
    activeSubItem,
    getChildMenuItemsForMainItem,
    fetch,
  }
}
