<script setup lang="ts">
// const props = defineProps<{}>()
// const emit = defineEmits<{}>()
// const { t } = useI18n({ useScope: 'local' })
// const { t: t } = useI18n({ useScope: 'global' })

// Loading the column titles from the center profile is not ideal.
// A better solution would be to store it on the menu model, so that we
// can also localize it better. It'll require a frontend feature to edit menus,
// so something for later.
const { currentCenterProfile } = await useCurrentCenter()
</script>

<template>
  <PublicFooterAddressColumn />

  <template v-if="currentCenterProfile">
    <PublicFooterDynamicTextColumn
      :title="currentCenterProfile?.footerColumn6Title || `Openingstijden`"
      :content="currentCenterProfile?.footerColumn6Content || ''"
    />
  </template>

  <PublicFooterGeneralInfoColumn />

  <PublicFooterLastColumn />
</template>

<i18n lang="json">
{
  "en": {
    "": ""
  },
  "nl": {
    "": ""
  }
}
</i18n>
