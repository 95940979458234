<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    /**
     * The slug of the menu to fetch.
     */
    menuSlug?: string
    /**
     * The prefix to add to the paths of the menu items that link to content pages.
     * Used when nesting pages in a specific scope.
     */
    contentPathPrefix?: string
  }>(),
  { menuSlug: 'main', contentPathPrefix: undefined },
)

// Initialing this before the defaults didn't work
const { contentPathPrefix: defaultContentPathPrefix } = useAppConfig()

// Prefix content items, if a content paths prefix is set.
// It would probably be better to have a project-wide config or function to call for this,
// because we have the same issue on the content pages overview.
const prefixedPath = (item: ContentMenuItem) => {
  const prefix = props.contentPathPrefix || defaultContentPathPrefix
  if (!props.contentPathPrefix || item.systemPageId) {
    return item.path
  }
  return prefix + item.path
}

const { isAuthenticated } = useAuth()

const { parentMenuItems, activeChildMenuItems, activeMainItem }
  = await useCmsMenu(props.menuSlug)
</script>

<template>
  <Container class="hidden justify-between overflow-x-auto md:flex">
    <nav class="flex transition-all duration-300 lg:overflow-x-hidden">
      <NuxtLink
        v-for="(item, index) in parentMenuItems"
        :key="index"
        :to="prefixedPath(item)"
        :class="{
          '!border-primary-500 !text-muted-900 dark:!text-muted-100':
            item == activeMainItem,
        }"
        class="text-muted-400 hover:text-muted-500 dark:text-muted-500 dark:hover:text-muted-400 min-w-max border-b-2 border-transparent p-3 text-center transition-colors duration-300 first:pl-0"
        exact-active-class="!border-primary-500 !text-muted-900 dark:!text-muted-100"
      >
        
        <BaseText size="sm" weight="medium">
          {{ item.name }}
        </BaseText>
      </NuxtLink>
    </nav>

    <NuxtLink
      v-if="isAuthenticated"
      :to="`/dashboard`"
      :class="{
        '!border-primary-500 !text-muted-900 dark:!text-muted-100': false,
      }"
      class="text-muted-400 hover:text-muted-500 dark:text-muted-500 dark:hover:text-muted-400 flex items-center justify-center border-b-2 border-transparent p-3 text-center transition-colors duration-300 first:pl-0"
      exact-active-class="!border-primary-500 !text-muted-900 dark:!text-muted-100"
    >
      <Icon name="ph:buildings" class="mr-1" />
      <BaseText
        size="sm"
        weight="medium"
        class="capitalize"
      >
        {{
          $t('menu.manage')
        }}
      </BaseText>
    </NuxtLink>
  </Container>

  
  <div
    class="border-muted-200 dark:border-muted-700 hidden w-full border-y transition-all duration-300 md:block"
  >
    <Container class="flex overflow-x-auto md:overflow-x-hidden">
      <NuxtLink
        v-for="(item, index) in activeChildMenuItems"
        :key="index"
        :to="prefixedPath(item)"
        class="text-muted-400 hover:text-muted-500 dark:text-muted-500 dark:hover:text-muted-400 flex min-w-max items-center justify-center border-b-2 border-transparent p-3 text-center transition-colors duration-300 first:pl-0"
        exact-active-class="!border-primary-500 !text-muted-900 dark:!text-muted-100"
      >
        <BaseText size="sm" weight="medium">
          {{ item.name }}
        </BaseText>
      </NuxtLink>
    </Container>
  </div>
</template>
