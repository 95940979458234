<script setup lang="ts">
const { title, content } = defineProps<{ title: string, content: string }>()

const { currentCenterProfile } = await useCurrentCenter()
</script>

<template>
  <PublicFooterColumn :name="title" class="lowercase">
    <slot />
    <p class="whitespace-break-spaces">
      {{ content || '-' }}
    </p>
  </PublicFooterColumn>
</template>
