<script setup lang="ts">
//  Subfooter items (Privacy, etc)
const { parentMenuItems: subFooterItems } = await useCmsMenu('footer_sub')
</script>

<template>
  <div class="col-span-2 flex justify-end gap-10 text-sm opacity-60">
    <div v-for="item in subFooterItems" :key="item.name">
      <NuxtLink :to="item.path" class="hover:text-white">
        {{
          item.name
        }}
      </NuxtLink>
    </div>
  </div>
</template>
