<template>
  <footer class="bg-primary-900 text-white-500 py-4">
    <!-- flex on, grid upwards because I couldn't make grid-cols-1 work. -->
    <Container
      class="text-primary-200 flex flex-col gap-10 pb-4 pt-10 sm:grid sm:grid-cols-2 md:grid-cols-4"
    >
      <slot />
    </Container>
  </footer>
</template>
