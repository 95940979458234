<script setup lang="ts">
const { currentCenter, currentCenterProfile } = await useCurrentCenter()
</script>

<template>
  <img
    v-if="currentCenterProfile.websiteLogoUrl"
    :src="currentCenterProfile.websiteLogoUrl"
    :alt="`Logo ${currentCenter.name}`"
  >
  <div v-else>
    <BaseHeading as="h1">
      {{ currentCenter.name }}
    </BaseHeading>
  </div>
</template>
