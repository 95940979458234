<script setup lang="ts">
// For more inspiration, check TairoTopNavigation.vue

// TODO: use activeMenuItem
const { menuItems, activeMenuItem } = await useAuthMenu()
</script>

<template>
  
  <div
    class="bg-muted-100 border-muted-200 dark:bg-muted-800 dark:border-muted-700 hidden w-full overflow-x-auto border-b transition-all duration-300 md:block"
  >
    <Container class="flex items-center">
      <NuxtLink
        v-for="(item, index) in menuItems"
        :key="index"
        :to="item.to"
        class="text-muted-400 hover:text-muted-500 dark:text-muted-500 dark:hover:text-muted-400 flex items-center justify-center border-b-2 border-transparent p-3 text-center transition-colors duration-300 first:pl-0"
        exact-active-class="!border-primary-500 !text-muted-900 dark:!text-muted-100"
      >
        <BaseText size="sm" weight="medium">
          {{ item.name }}
        </BaseText>
      </NuxtLink>
    </Container>
  </div>
</template>
