<script setup lang="ts">
const { t } = useI18n({
  useScope: 'local',
})

const { selectedOrganizationId, selectedOrganization, userOrganizations } = storeToRefs(
  useCurrentOrganizationStore(),
)

const target = ref(null)
const open = ref(false)

function openDropdown() {
  open.value = true
}

onClickOutside(target, () => (open.value = false))

const { toast } = useSuccessToaster()

function selectOrganization(organization: Organization) {
  selectedOrganizationId.value = organization.id
  open.value = false

  toast({
    title: t('toast.title', {
      organization: organization.name,
    }),
    message: t('toast.message'),
  })
}
</script>

<template>
  <div
    v-if="userOrganizations.length > 1"
    ref="target"
    class="group/organization relative z-10 ms-auto w-full max-w-[170px] md:ms-0 md:max-w-[280px]"
  >
    <button
      v-if="selectedOrganization"
      type="button"
      class="bg-muted-50 dark:bg-muted-950 group-hover/organization:bg-muted-100 dark:group-hover/organization:bg-muted-900/60 w-full max-w-[170px] rounded-xl py-2 pe-3 ps-2 transition-colors duration-300 md:max-w-[280px]"
      :class="open && 'bg-muted-100 dark:bg-muted-900/60'"
      @click="openDropdown()"
    >
      <span class="flex w-full items-center gap-3 text-start">
        <OrganizationAvatar
          size="xxs"
          :organization="selectedOrganization"
          :show-tooltip="false"
        />
        <div>
          <BaseText
            size="sm"
            class="text-muted-800 dark:text-muted-200 text line-clamp-1 block"
          >
            {{ selectedOrganization.name }}
          </BaseText>
        </div>
        <Icon
          name="lucide:chevrons-up-down"
          class="text-muted-400 ms-auto size-4 transition-transform duration-300"
          :class="open && 'rotate-180'"
        />
      </span>
    </button>
    <Transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      
      <div
        v-if="open"
        class="border-muted-200 dark:border-muted-800 dark:bg-muted-950 shadow-muted-400/10 dark:shadow-muted-800/10 absolute end-0 top-12 w-full min-w-[280px] overflow-hidden rounded-xl border bg-white shadow-xl md:start-0 md:min-w-[280px]"
      >
        <div class="flex h-[calc(100%_-_2.5rem)] flex-col p-3">
          <BaseHeading
            as="h4"
            size="sm"
            weight="medium"
            class="text-muted-400"
          >
            {{ t('organizations') }}
          </BaseHeading>
          <div class="my-3">
            <ul class="space-y-1">
              <li
                v-for="organization in userOrganizations"
                :key="organization.id"
              >
                <button
                  type="button"
                  class="hover:bg-muted-100 dark:hover:bg-muted-800 flex w-full items-center gap-2 rounded-lg py-2 pe-4 ps-2 transition-colors duration-200"
                  @click="selectOrganization(organization)"
                >
                  <OrganizationAvatar :organization="organization" size="xxs" />
                  <BaseText size="sm" class="text-left">
                    {{
                      organization.name
                    }}
                  </BaseText>
                  <Icon
                    v-if="selectedOrganization === organization"
                    name="lucide:check"
                    class="text-primary-500 ms-auto size-4"
                  />
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "organizations": "Organizations",
    "toast": {
      "title": "Switched to {organization}",
      "message": "You are now logged in as this organization"
    }
  },
  "nl": {
    "organizations": "Organisaties",
    "toast": {
      "title": "Gewisseld naar {organization}",
      "message": "Je bent nu ingelogd als deze organisatie"
    }
  }
}
</i18n>
