export interface MenuItem {
  name: string
  icon: { name: string, class: string }
  activePath: string
  to: string
}

export default function useActiveMenuItem(
  // A string used as an id for the selected menu state
  menuIdentifier: string,
  // The menu items of the menu
  menuItems: Ref<TairoTopnavResolvedConfig[] | MenuItem[] | undefined>,
) {
  const route = useRoute()

  const activeMenuItem = computed(() => {
    return menuItems.value?.find((item) => {
      if (!item) return false
      if (item.activePath) {
        return route.path.startsWith(item.activePath)
      }
      if (item.to) {
        return route.path.startsWith(item.to.toString())
      }
      return false
    })
  })

  const selectedMenuItem = useState(
    `${menuIdentifier}-selected-menu-item`,
    () => activeMenuItem.value,
  )
  watch(activeMenuItem, (item) => {
    selectedMenuItem.value = item
  })

  return { activeMenuItem, selectedMenuItem }
}
