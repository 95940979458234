// TODO: i18n
export async function useAuthMenu() {
  const { currentUser } = storeToRefs(useCurrentUserStore())
  const { isVolunteer, isAdmin, isCenterAdmin } = useCurrentOrganizationRoles()

  const menuItems = computed(() => {
    if (!currentUser) return []

    return [
      {
        name: 'Dashboard',
        icon: { name: 'ph:gauge-duotone', class: 'w-6 h-6' },
        activePath: '/',
        to: '/dashboard',
      },
      isVolunteer.value
        ? {
            name: 'Profiel',
            icon: { name: 'ph:person-circle-duotone', class: 'w-6 h-6' },
            activePath: '/profiel',
            to: '/profiel',
          }
        : undefined,
      isAdmin.value
        ? {
            name: 'Vacatures',
            icon: { name: 'ph:suitcase-duotone', class: 'w-6 h-6' },
            activePath: '/vacancies',
            to: '/vacancies',
          }
        : undefined,
      isCenterAdmin.value
        ? {
            name: 'Vacaturebank',
            icon: { name: 'ph:suitcase-duotone', class: 'w-6 h-6' },
            activePath: '/vacancies/search',
            to: '/vacancies/search',
          }
        : undefined,
      isAdmin.value
        ? {
            name: 'Aanmeldingen',
            icon: { name: 'ph:user-focus', class: 'w-6 h-6' },
            activePath: '/applications',
            to: '/applications',
          }
        : undefined,
      isAdmin.value
        ? {
            name: 'Vrijwilligerspool',
            icon: { name: 'ph:user-focus', class: 'w-6 h-6' },
            activePath: '/vrijwilligerspool',
            to: '/vrijwilligerspool',
          }
        : undefined,
      isAdmin.value
        ? {
            name: 'Reacties',
            icon: { name: 'ph:user-focus', class: 'w-6 h-6' },
            activePath: '/profile-replies',
            to: '/profile-replies',
          }
        : undefined,
      isCenterAdmin.value
        ? {
            name: 'Organisaties',
            icon: { name: 'ph:users-duotone', class: 'w-6 h-6' },
            activePath: '/organizations',
            to: '/organizations',
          }
        : undefined,
      isAdmin.value
        ? {
            name: 'Gebruikers',
            icon: { name: 'ph:users-duotone', class: 'w-6 h-6' },
            activePath: '/users',
            to: '/users',
          }
        : undefined,
      isCenterAdmin.value
        ? {
            name: 'Content',
            activePath: '/content',
            to: '/content',
          }
        : undefined,
      isAdmin.value
        ? {
            name: isCenterAdmin.value ? 'Instellingen' : 'Organisatie',
            activePath: '/settings',
            to: '/settings',
          }
        : undefined,
      {
        name: 'Berichten',
        activePath: '/conversations',
        to: '/conversations',
      },
    ].filter(i => i !== undefined) as MenuItem[]
  })

  const { activeMenuItem, selectedMenuItem } = useActiveMenuItem(
    'auth',
    menuItems,
  )

  return {
    menuItems,
    activeMenuItem,
    selectedMenuItem,
  }
}
