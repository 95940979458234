export default async function useFooterItems() {
  // Loading the column titles from the center profile is not ideal.
  // A better solution would be to store it on the menu model, so that we
  // can also localize it better. It'll require a frontend feature to edit menus,
  // so something for later.
  const { currentCenterProfile } = await useCurrentCenter()

  const { parentMenuItems: footer1Items } = await useCmsMenu('footer_1')
  const { parentMenuItems: footer2Items } = await useCmsMenu('footer_2')
  const { parentMenuItems: footer3Items } = await useCmsMenu('footer_3')
  const { parentMenuItems: footer4Items } = await useCmsMenu('footer_4')

  const columns: FooterColumn[] = [
    {
      name: currentCenterProfile.value?.footerColumn1Title ?? 'Kolom 1',
      items: footer1Items.value,
    },
    {
      name: currentCenterProfile.value?.footerColumn2Title ?? 'Kolom 1',
      items: footer2Items.value,
    },
    {
      name: currentCenterProfile.value?.footerColumn3Title ?? 'Kolom 3',
      items: footer3Items.value,
    },
    {
      name: currentCenterProfile.value?.footerColumn4Title ?? 'Kolom 4',
      items: footer4Items.value,
    },
  ]

  return { columns }
}
