<script setup lang="ts">
const { getPlatformIconClass, platforms } = await useSocialMedia()
</script>

<template>
  <div class="col-span-2 flex gap-6 text-sm">
    <a
      v-for="platform in platforms"
      :key="platform?.name"
      target="_blank"
      rel="noopener nofollower"
      :href="platform?.url"
    >
      <Icon
        v-if="platform?.name"
        :name="getPlatformIconClass(platform.name)"
        class="block size-6 hover:text-white"
      />
    </a>
  </div>
</template>
